import React from 'react';
import {
  Container,
  Section,
  Box,
  Layout,
  Grid,
  DeleteRequestForm,
} from '../../components';

const ContactPage = () => (
  <Layout title="Account Deletion Request">
    <Container mt={{ _: 8, md: 9 }}>
      <Section p={{ xs: 2, md: 0 }} mt={{ xs: '20px', md: '100px' }}>
        <Grid gridTemplateColumns={{ _: '1fr', sm: '1fr 1fr' }}>
          <Box>
            <DeleteRequestForm showSidebar={false} />
          </Box>
        </Grid>
      </Section>
    </Container>
  </Layout>
);

export default ContactPage;
